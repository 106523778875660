.space-text-search {
    line-height: 1rem;
}

span {
    font-size: 0.7rem;
    margin: 0;
}

.cousel-caption {
    background-color: rgba(0, 0, 0, 0.411);
    border: solid 1px rgba(0, 0, 0, 0.411);
    border-radius: 1em;
    font-size: small;
    line-height: 0.8rem;
}

.cousel-caption h3 {
    line-height: 0.7em;
}

.float-whatsapp div {
    background-color: green !important;
    color: white !important;
}